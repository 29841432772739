<template>
  <div>
    <v-card outlined persistent>
      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-supervisor</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="title" v-if="lead.service_manager">
              {{ lead.service_manager.name }}
              <span v-if="!lead.service_manager.is_active"
                ><v-icon>mdi-lock</v-icon></span
              >
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ __("Nenhum gestor atribuído") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ __("Gestor responsável") }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <scope-provider scope="leads.update_manager">
              <v-btn @click="removeManager()" v-if="lead.service_manager" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn @click="openManagerDialog" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </scope-provider>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <v-dialog v-model="manager_dialog" max-width="650" eager>
      <v-card>
        <v-card-title>
          {{ __("Escolher gestor") }}
          <v-spacer />
          <v-btn @click="resetDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ __("Gestor responsável pelos processos") }}
        </v-card-subtitle>
        <v-form @submit.prevent="handleSubmitForm" :disabled="loading_submit">
          <v-card-text class="pt-0">
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="search"
                  @keydown.enter.prevent.stop="getManagers()"
                  @click:clear="clearFilter()"
                  class="px-3"
                  :label="__('Pesquisar por nome')"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  solo
                  autofocus
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :items="managers"
              @input="manager = $event[0] || null"
              :show-select="
                $store.getters['auth/getScope']('leads.update_manager')
              "
              :loading="loading_managers"
              single-select
              :headers="[
                {
                  text: this.__('Gestor'),
                  value: 'name',
                },
                {
                  text: this.__('Status'),
                  value: 'is_active',
                },
              ]"
              :item-class="(item) => (!item.is_active ? 'red--text' : '')"
            >
              <template v-slot:item.is_active="{ item }">
                {{ item.is_active ? __("Ativo") : __("Bloqueado") }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="resetDialog()" text> {{ __("Cancelar") }} </v-btn>
            <v-btn
              @click="handleSubmitForm()"
              color="primary"
              :disabled="!manager"
            >
              {{ __("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ScopeProvider from "@/components/ScopeProvider";
import { index as indexManagers } from "@/services/service_managers";
import { change_manager as changeLeadManager } from "@/services/lead";
import i18n from "@/mixins/i18n";

export default {
  name: "resume-management-card",
  components: { ScopeProvider },
  mixins: [i18n],
  data() {
    return {
      i18n_prefix: "managers",
      manager_dialog: false,
      loading_teams: false,
      loading_managers: false,
      loading_submit: false,
      teams: [],
      managers: [],
      team: {},
      manager: {},
      search: "",
    };
  },
  methods: {
    openManagerDialog() {
      this.manager_dialog = true;
      this.clearFilter();
    },
    resetDialog() {
      this.manager_dialog = false;
    },
    async removeManager() {
      if (!confirm(this.__("Confirma remoção do gestor?"))) return;
      const oldLead = { ...this.lead };

      try {
        this.$store.commit("setLead", {
          ...this.lead,
          team: null,
          service_manager: null,
        });

        await changeLeadManager(this.lead?.id, { service_manager: null });

        this.$store.commit("alert", this.__("Salvo!"));
      } catch (exception) {
        this.$store.commit("setLead", {
          ...oldLead,
        });

        if (exception.response?.data?.message) {
          this.$store.commit("error", exception.response.data.message);
        }
      }
    },
    async handleSubmitForm() {
      if (
        !this.manager.is_active &&
        !confirm(this.__("Confirmar atribuição a gestor bloqueado?"))
      ) {
        return;
      }

      this.loading_submit = true;
      try {
        await changeLeadManager(this.lead?.id, {
          service_manager_id: this.manager.id,
        });
        this.$store.commit("setLead", {
          ...this.lead,
          team: this.team,
          service_manager: this.manager,
        });
        this.$store.commit("alert", this.__("Salvo!"));
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.loading_submit = false;
        this.resetDialog();
      }
    },
    clearFilter() {
      this.search = "";
      this.getManagers();
    },
    async getManagers() {
      this.managers = [];
      this.loading_managers = true;
      try {
        const response = await indexManagers({
          filter: this.search,
        });
        this.managers = response.data;
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.loading_managers = false;
      }
    },
    loadForm() {
      this.manager = {
        id: this.lead.service_manager_id,
      };
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
  },
  watch: {
    lead: function (value, oldValue) {
      const old_lead_id = oldValue?.id;
      const lead_id = value?.id;

      if (old_lead_id == null && lead_id != null) {
        this.loadForm();
      }
    },
    team: function (value, old_value) {
      if (old_value === null && value.id && this.teams.length == 0) {
        this.teams = [value];
      } else {
        this.getManagers();
      }
    },
    manager: function (value, old_value) {
      if (old_value === null && value.id && this.managers.length == 0) {
        this.managers = [value];
      }
    },
  },
  created() {
    this.loadForm();
  },
};
</script>
